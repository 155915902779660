import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 100000,
})

const RequestInterceptor = {
  _config: (config) => {
    const getParse = JSON.parse(localStorage.getItem('token')) || null;
    config.headers['Authorization'] = `bearer ${getParse}`;

    return config;
  },
  _error: (error) => {
    Promise.reject(error);
  },
};

const ResponseInterceptor = {
  _response: (response) => {
    const { data } = response
    return data
  },
  _error: (error) => {
    if(error.response.status == 401){
      localStorage.removeItem('username')
      localStorage.removeItem('password')
      localStorage.removeItem('isLogin')
      localStorage.removeItem('token')
      localStorage.removeItem('permission')
      localStorage.removeItem('id')
      window.location.href = '/login'
    }else{
      return Promise.reject(error);
    }
  },
};

// API Request interceptor
service.interceptors.request.use(
  (config) => RequestInterceptor._config(config),
  (error) => RequestInterceptor._error(error)
);

service.interceptors.response.use(
  (response) => ResponseInterceptor._response(response),
  (error) => ResponseInterceptor._error(error)
);

export default service
