export default [
    {
        path: "/",
        name: "index",
        meta: { title: "Light Able Vue3 Admin & Dashboard Template | Phoenixcoded" },
        component: () => import("../views/customer.vue"),
    },
    {
        path: "/login",
        name: "login",
        meta: { title: "Light Able Vue3 Admin & Dashboard Template | Phoenixcoded" },
        component: () => import("../views/login.vue"),
    },
    {
        path: "/customer",
        name: "customer",
        meta: { title: "Light Able Vue3 Admin & Dashboard Template | Phoenixcoded" },
        component: () => import("../views/customer.vue"),
    },
    {
        path: "/report/logs/callback",
        name: "log-callback",
        meta: { title: "Light Able Vue3 Admin & Dashboard Template | Phoenixcoded" },
        component: () => import("../views/report-log.vue"),
    },
    
]